import React from 'react';
import { Trans } from 'react-i18next';

import Dropdown from '../dropdown';

import { EPredicateOperators } from '@common/definitions';
import { Predicate } from '@common/types/objects';

const predicateNumberOperators = [
  EPredicateOperators.EQ,
  EPredicateOperators.GT,
  EPredicateOperators.LT,
  EPredicateOperators.GTE,
  EPredicateOperators.LTE,
];

type PredicateSelectorNumberOwnProps = {
  predicate: Predicate,
  onChange: (newPredicate: Partial<Predicate>) => void,
};

export const PredicateSelectorNumber = ({
  predicate, onChange
}: PredicateSelectorNumberOwnProps) => (
  <div>
    {predicateNumberOperators.map((item) => (
      <>
        <Dropdown.CheckboxItem
          key={item}
          value={predicate.comparison === item}
          onChange={() => {
            if (predicate.comparison === item) return false;

            const result: any = {
              ...predicate,
              comparison: item,
              value: item === EPredicateOperators.BETWEEN ?
                // @ts-expect-error
                [predicate.value, predicate.value + 1] :
                predicate.comparison === EPredicateOperators.BETWEEN ?
                  // @ts-expect-error
                  predicate.value[0] :
                  predicate.value
            };
            return onChange(result);
          }}
          label={<Trans i18nKey="common:predicates_filter_operator" values={{ context: item }} />}
        />
        {predicate.comparison === item && (
        <div className="dropdown-input PredicatesFilter__Filters__BetweenInput">
          {predicate.comparison === EPredicateOperators.BETWEEN ? (
            <>
              <input
                className="Form__control"
                type="number"
                // @ts-expect-error
                max={predicate.value[1]}
                min={0}
                // @ts-expect-error
                defaultValue={predicate.value[0]}
                // @ts-expect-error
                onChange={(e) => onChange({ value: [parseInt(e.target.value || '0', 10), predicate.value[1]] })}
              />
              <input
                className="Form__control"
                type="number"
                max={9999}
                // @ts-expect-error
                min={predicate.value[0]}
                // @ts-expect-error
                defaultValue={predicate.value[1]}
                // @ts-expect-error
                onChange={(e) => onChange({ value: [predicate.value[0], parseInt(e.target.value || '0', 10)] })}
              />
            </>
          ) : (
            <input
              className="Form__control"
              type="number"
              max={9999}
              min={0}
              // @ts-expect-error
              defaultValue={predicate.value}
              onChange={(e) => onChange({ value: parseInt(e.target.value || '0', 10) })}
            />
          )}
        </div>
        )}
      </>
    ))}
  </div>
);
