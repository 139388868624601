import { EditorState, RichUtils } from 'draft-js';
import { EditorPlugin } from '@draft-js-plugins/editor';

export enum TextStyles {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
}

export enum ShortcutCommands {
  BOLD = 'SHORTCUT_BOLD',
  ITALIC = 'SHORTCUT_ITALIC',
}

export const createToggleStyle = (
  getEditorState: (() => EditorState),
  setEditorState: ((state: EditorState) => void),
) => (style: TextStyles) => {
  const stateWithModifiedStyle = RichUtils.toggleInlineStyle(getEditorState(), style);
  setEditorState(stateWithModifiedStyle);
};

export const createKeyboardShortcutsPlugin = (): EditorPlugin => ({
  keyBindingFn: (event) => {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case 'b':
          return ShortcutCommands.BOLD;

        case 'i':
          return ShortcutCommands.ITALIC;

        default:
          break;
      }
    }
  },
  handleKeyCommand: (
    command: ShortcutCommands | string,
    state,
    eventTimeStamp,
    { getEditorState, setEditorState },
  ) => {
    const toggleStyle = createToggleStyle(getEditorState, setEditorState);

    switch (command) {
      case ShortcutCommands.BOLD:
        toggleStyle(TextStyles.BOLD);
        break;

      case ShortcutCommands.ITALIC:
        toggleStyle(TextStyles.ITALIC);
        break;

      default:
        return 'not-handled';
    }

    return 'handled';
  },
});
