import React, { useState } from 'react';
import * as R from 'ramda';

import Dropdown from '../dropdown';
import type { Predicate } from '@common/types/objects';
import { Trans } from 'react-i18next';
import { EPredicateOperators } from '@common/definitions';

type Option = Record<string, string>;

type PredicateSelectorMultiSelectOwnProps = {
  predicate: Predicate,
  onChange: (newPredicate: Partial<Predicate>) => void,
  options: Option[];
  labelKey: string;
  valueKey: string;
};

export const PredicateSelectorMultiSelect = ({
  predicate, onChange: parentOnChange,
  options = [], labelKey = 'name', valueKey = 'id',
}: PredicateSelectorMultiSelectOwnProps) => {
  const [search, setSearch] = useState('');

  const searchText = search && search.toLowerCase();
  const filteredOptions = options.filter((option) => option[labelKey].toLowerCase().indexOf(searchText) > -1);

  // @ts-expect-error
  const areAllSelected = predicate.value.length === options.length;

  const onChange = ({ value }: Partial<Predicate>) => parentOnChange({ comparison: EPredicateOperators.IN, value });

  return (
    <>
      {/* @ts-expect-error */}
      <Dropdown.SearchInput debounce={false} filter={false} onSearch={setSearch} />
      <a
        className="dropdown-select-all"
        onClick={() => onChange({ value: areAllSelected ? [] : R.pluck('id', filteredOptions) })}
      >
        <b>
          <Trans
            i18nKey={
              areAllSelected ?
                'common:predicates_filter_unheck_all' :
                'common:predicates_filter_check_all'
            }
          />
        </b>
      </a>
      {filteredOptions
        .sort((a, b) => {
          // @ts-expect-error
          if (predicate.value.includes(a[valueKey]) && !predicate.value.includes(b[valueKey])) return -1;
          // @ts-expect-error
          if (predicate.value.includes(b[valueKey]) && !predicate.value.includes(a[valueKey])) return +1;

          return a[labelKey] > b[labelKey] ? 1 : -1;
        })
        .map((option) => (
          <Dropdown.CheckboxItem
            key={option[valueKey]}
            // @ts-expect-error
            value={predicate.value.includes(option[valueKey])}
            onChange={(checked) => (checked
              // @ts-expect-error
              ? onChange({ value: R.append(option[valueKey], predicate.value) })
              // @ts-expect-error
              : onChange({ value: R.reject(R.equals(option[valueKey]), predicate.value) })
            )}
            // @ts-ignore
            label={option[labelKey]}
          />
        ))}
    </>
  );
};
