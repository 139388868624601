import React from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import { EditorPlugin } from '@draft-js-plugins/editor';
import { createStore, Store } from '@draft-js-plugins/utils';

import { getUrlRegex } from '@common/constants';
import { isEqualWithFlatDiff } from '@utils/isEqualWithFlatDiff';
import { LinkPreviewComponent } from './link-preview-component';

import type { LinkPreviewComponentProps } from './link-preview-component';

export type CreateLinkPreviewStoreContent = {
  linkForPreview?: string;
  allPreviousLinks: string[];
};

export type CreateLinkPreviewStore = Store<CreateLinkPreviewStoreContent>;

type LinkPreviewProps = Pick<LinkPreviewComponentProps, 'onPreviewChanged'>;

export type CreateLinkPreviewPlugin = EditorPlugin & {
  LinkPreview: (props: LinkPreviewProps) => JSX.Element;
};

export const getLinksFromState = (store: CreateLinkPreviewStore) => (editorState: EditorState) => {
  const { blocks } = convertToRaw(editorState.getCurrentContent());
  const asText = blocks.reduce((acc, { text }) => `${acc} ${text}`, '');
  const allLinks = Array.from(asText.matchAll(getUrlRegex())).map(([link]) => link);

  const allPreviousLinks = store.getItem('allPreviousLinks');

  if (allLinks.length > 0) {
    const [isEqual, diff] = isEqualWithFlatDiff(allLinks, allPreviousLinks);

    if (!isEqual && diff) {
      const newLinkForPreview = diff.at(-1); // Recently modified link or last URL from content.
      store.updateItem('linkForPreview', newLinkForPreview);
      store.updateItem('allPreviousLinks', allLinks);
    }
  } else if (allPreviousLinks.length > 0) {
    store.updateItem('linkForPreview', undefined);
    store.updateItem('allPreviousLinks', []);
  }
};

export const createLinkPreviewPlugin = (): CreateLinkPreviewPlugin => {
  const store = createStore<CreateLinkPreviewStoreContent>({
    linkForPreview: undefined,
    allPreviousLinks: [],
  });

  const resolveLinks = getLinksFromState(store);

  return {
    initialize: (pluginFunctions) => {
      const editorState = pluginFunctions.getEditorState();
      resolveLinks(editorState);
    },
    onChange: (editorState) => {
      resolveLinks(editorState);
      return editorState;
    },
    LinkPreview: (props) => <LinkPreviewComponent {...props} store={store} />,
  };
};
