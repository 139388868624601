import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { EPredicateOperators } from '@common/definitions';
import { Predicate } from '@common/types/objects';
import Icon from '../icon';

type PredicateSelectorDateOwnProps = {
  predicate: Predicate,
  onChange: (newPredicate: Partial<Predicate>) => void,
};

// @ts-expect-error
const CustomDateInput = (inputProps) => (
  <>
    <input {...inputProps} className="Form__control" />
    <Icon type="calendar_today" />
  </>
);

const toNativeDate = (date?: Date) => (date ? moment(date).toDate() : null);

export const PredicateSelectorDate = ({
  predicate, onChange: parentOnChange,
}: PredicateSelectorDateOwnProps) => {
  const { t } = useTranslation();

  const onChange = ({ value }: Partial<Predicate>) => parentOnChange({
    comparison: EPredicateOperators.BETWEEN,
    value,
  });

  return (
    <div>
      <h4><Trans i18nKey="common:predicates_date_from" /></h4>
      <div className="dropdown-input">
        <ReactDatePicker
          customInput={<CustomDateInput />}
          dateFormat="dd-MM-yyyy"
          // @ts-expect-error
          selected={predicate.value[0] && toNativeDate(predicate.value[0])}
          // @ts-expect-error
          maxDate={predicate.value[1]}
          // @ts-expect-error
          onChange={(date) => onChange({ value: [date, predicate.value[1]] })}
          placeholderText={t('common:predicate_date_from_date')}
        />
      </div>
      <h4><Trans i18nKey="common:predicates_date_to" /></h4>
      <div className="dropdown-input">
        <ReactDatePicker
          customInput={<CustomDateInput />}
          dateFormat="dd-MM-yyyy"
          // @ts-expect-error
          selected={predicate.value[1] && toNativeDate(predicate.value[1])}
          // @ts-expect-error
          minDate={predicate.value[0]}
          // @ts-expect-error
          onChange={(date) => onChange({ value: [predicate.value[0], date] })}
          placeholderText={t('common:predicate_date_to_date')}
        />
      </div>

    </div>
  );
};
