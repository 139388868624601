import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import Dropdown from '../dropdown';
import { Organisation, Predicate } from '@common/types/objects';

type Option = Record<string, string>;

type PredicateSelectorAsyncMultiSelectOwnProps = {
  predicate: Predicate,
  onChange: (newPredicate: Partial<Predicate>) => void,
  getOptions: (organisationId: string, search: string) => Promise<Option[]>;
  updateSelectedOptions: (newSelectedOptions: Option[]) => void;
  organisation: Organisation;
  options: Option[];
  labelKey: string;
  valueKey: string;
};

export const PredicateSelectorAsyncMultiSelect = ({
  predicate,
  onChange,
  options, // contains the selected values
  getOptions,
  updateSelectedOptions,
  labelKey = 'name',
  valueKey = 'id',
  organisation,
}: PredicateSelectorAsyncMultiSelectOwnProps) => {
  const [search, setSearch] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);

  const handleSearch = async (newSearch: string) => {
    const result = await getOptions(organisation.id, newSearch);

    // @ts-expect-error
    setSearchOptions(result || []);
  };

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  let checkedOptions: Option[];
  let uncheckedOptions: Option[];
  if (search) {
    const isSelected = (option: Option) => {
      return options.some((o) => o[valueKey] === option[valueKey]);
    };
    checkedOptions = searchOptions.filter(isSelected);
    uncheckedOptions = searchOptions.filter((o: Option) => !isSelected(o));
  } else {
    checkedOptions = options;
    uncheckedOptions = [];
  }

  return (
    <>
      {/* @ts-expect-error */}
      <Dropdown.SearchInput filter={false} onSearch={setSearch} />
      {checkedOptions.map((option: Option) => (
        <Dropdown.CheckboxItem
          key={option[valueKey]}
          value
          onChange={() => {
            updateSelectedOptions(R.reject(R.propEq(valueKey, option[valueKey]), options));
            // @ts-expect-error
            onChange({ value: R.reject(R.equals(option[valueKey]), predicate.value) });
          }}
          // @ts-ignore
          label={option[labelKey]}
        />
      ))}
      {uncheckedOptions.map((option) => (
        <Dropdown.CheckboxItem
          key={option[valueKey]}
          value={false}
          onChange={() => {
            updateSelectedOptions(R.append(option, options));
            // @ts-expect-error
            onChange({ value: R.append(option[valueKey], predicate.value) });
          }}
          // @ts-ignore
          label={option[labelKey]}
        />
      ))}
    </>
  );
};
