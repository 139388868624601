export interface AnchorPluginTheme {
  input: string;
  inputInvalid: string;
  link: string;
}

export const defaultTheme: AnchorPluginTheme = {
  input: 'DraftJSAnchor__Input',
  inputInvalid: 'DraftJSAnchor__Input--invalid',
  link: 'DraftJSAnchor__Link',
};
