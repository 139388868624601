import { EParentTypes } from '../../../definitions';
import Api from '../../../services/api';

export const fetchMentions = (
  search: string,
  target: { type: EParentTypes, id: string, parent: { organisation_id: string } },
) => {
  let endpoint;

  const { type, id } = target;

  switch (type) {
    case EParentTypes.ORGANISATION:
      endpoint = `/v2/organisations/${id}/users`;
      break;
    case EParentTypes.NETWORK:
      endpoint = `/v2/networks/${id}/users`;
      break;
    case EParentTypes.TEAM:
      endpoint = `/v1/teams/${id}/users`;
      break;
    case EParentTypes.CHANNEL:
      endpoint = `/v1/organisations/${target.parent.organisation_id}/channels/${id}/users`;
      break;
    case EParentTypes.EVENT:
      endpoint = `/v1/organisations/${target.parent.organisation_id}/events/${id}/guests`;
      break;
    default:
      return { data: [] };
  }

  return Api.get(`${endpoint}?q=${search}&limit=5`);
};
