import React from 'react';

import { Icon } from '@common/components/icon';
import { combineClassNames } from '@utils/combineClassNames';
import { openInNewTab } from '@utils/open-in-new-tab';

import type { LinkPreview } from '@common/types/objects';

type StaticLinkPreviewVariants =
  | 'default'
  | 'editor';

type StaticLinkPreviewProps = {
  linkPreview: LinkPreview;
  onClose?: () => void;
  className?: string;
  variant?: StaticLinkPreviewVariants;
};

export const StaticLinkPreview = ({
  linkPreview,
  onClose,
  className,
  variant = 'default',
}: StaticLinkPreviewProps) => {
  const fullClassName = combineClassNames('StaticLinkPreview', `StaticLinkPreview--${variant}`, className);

  return (
    <div
      className={fullClassName}
      onClick={variant !== 'editor' ? () => openInNewTab(linkPreview.url) : undefined}
      role="link"
    >
      {onClose && (
        <Icon
          type="close"
          role="button"
          className="StaticLinkPreview__RemoveButton"
          onClick={onClose}
        />
      )}

      {linkPreview.image && (
        <img
          className="StaticLinkPreview__ImagePreview"
          src={linkPreview.image.url}
          alt="LinkPreviewImage"
        />
      )}

      <div className="StaticLinkPreview__Info">
        <div className="StaticLinkPreview__Header">
          {linkPreview.icon && (
            <img
              src={linkPreview.icon.url}
              alt="LinkPreviewIcon"
            />
          )}
          <span>{linkPreview.name}</span>
        </div>

        <span className="StaticLinkPreview__Title">
          {linkPreview.title}
        </span>

        {linkPreview.description && (
          <span className="StaticLinkPreview__Description">
            {linkPreview.description}
          </span>
        )}
      </div>
    </div>
  );
};
