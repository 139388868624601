import React, { ComponentType, ReactElement } from 'react';
import EditorUtils from '@draft-js-plugins/utils';
import AddLinkForm from './add-link-form';
import type { OverrideContentProps } from './add-link-form';
import { DefaultLinkButtonProps } from './default-link-button';
import type { AnchorPluginTheme } from '../theme';
import type { AnchorPluginStore } from '../anchor-plugin';

export interface LinkButtonTheme {
  button?: string;
  active?: string;
  buttonWrapper?: string;
}

export interface LinkButtonPubParams {
  theme?: LinkButtonTheme;
  onOverrideContent(
    component: ComponentType<OverrideContentProps> | undefined
  ): void;
}

type LinkButtonParams = LinkButtonPubParams & OverrideContentProps & {
  ownTheme: AnchorPluginTheme;
  store: AnchorPluginStore;
  placeholder?: string;
  onRemoveLinkAtSelection(): void;
  validateUrl?(url: string): boolean;
  linkButton: ComponentType<DefaultLinkButtonProps>;
};

const LinkButton = ({
  ownTheme,
  placeholder,
  onOverrideContent,
  validateUrl,
  theme,
  onRemoveLinkAtSelection,
  store,
  linkButton: InnerLinkButton,
  setEditorState,
  getEditorState,
}: LinkButtonParams): ReactElement => {
  const editorState = store.getEditorState?.();
  const hasLinkSelected = editorState
    ? EditorUtils.hasEntity(editorState, 'LINK')
    : false;

  const currUrl = ((): string | undefined => {
    if (!editorState) return;
    const currEntity = EditorUtils.getCurrentEntity(editorState);
    if (!currEntity) return;

    const { url } = currEntity.getData();
    return url;
  })();

  const handleOpenLinkEdit = () => {
    onOverrideContent(() => (
      <AddLinkForm
        getEditorState={getEditorState}
        setEditorState={setEditorState}
        theme={ownTheme}
        placeholder={placeholder}
        validateUrl={validateUrl}
        onOverrideContent={onOverrideContent}
        onRemoveLinkAtSelection={onRemoveLinkAtSelection}
        previousValue={currUrl}
      />
    ));
  };

  return (
    <div className="DraftJSAnchor__LinkButton">
      <InnerLinkButton
        hasLinkSelected={hasLinkSelected}
        openLinkEdit={handleOpenLinkEdit}
        theme={theme}
      />
    </div>
  );
};

export default LinkButton;
