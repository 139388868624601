import React, { useState, useEffect } from 'react';

import { Spinner } from '@common/components/spinner';
import { StaticLinkPreview } from '@common/components/static-link-preview';
import { useDebounceOnChange, useAppSelector } from '@hooks/index';
import { Api } from '@services/api';

import type { LinkPreview } from '@common/types/objects';
import type { CreateLinkPreviewStore } from './link-preview-plugin';

export type LinkPreviewComponentProps = {
  store: CreateLinkPreviewStore;
  onPreviewChanged?: (newLinkPreview: LinkPreview) => void;
};

export const LinkPreviewComponent = ({ store, onPreviewChanged }: LinkPreviewComponentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkPreview, setLinkPreview] = useState<LinkPreview>();
  const organisationId = useAppSelector((state) => state.organisation.selected.id);

  const [setLinkUrl] = useDebounceOnChange<string | undefined>(undefined, async (link) => {
    setLinkPreview(undefined);

    if (link) {
      const query = Api.utils.toQuery({ url: link });

      try {
        const { data } = await Api.get<{ data: LinkPreview }>(`/v1/organisations/${organisationId}/link-preview?${query}`);
        setLinkPreview(data);
        onPreviewChanged?.(data);
      } catch (e) {
        const minimalLinkPreview: LinkPreview = {
          url: link,
          name: link,
          title: link,
          description: null,
          image: null,
          icon: null,
        };

        setLinkPreview(minimalLinkPreview);
        onPreviewChanged?.(minimalLinkPreview);
      }

      setIsLoading(false);
    }
  }, 2000);

  const handleSetLinkForPreview = (newLink: string | undefined) => {
    if (linkPreview) return;

    if (isLoading && !newLink) setIsLoading(false);
    if (!isLoading && newLink) setIsLoading(true);
    setLinkUrl(newLink);
  };

  useEffect(() => {
    store.subscribeToItem('linkForPreview', handleSetLinkForPreview);

    return () => store.unsubscribeFromItem('linkForPreview', handleSetLinkForPreview);
  }, [handleSetLinkForPreview]);

  if (isLoading) return <Spinner centered />;
  if (!linkPreview) return null;

  return (
    <StaticLinkPreview
      linkPreview={linkPreview}
      onClose={() => setLinkPreview(undefined)}
      variant="editor"
    />
  );
};
